import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Autocomplete, TextField, IconButton, FormControl, Grid, Modal, Button, Typography, useTheme, Checkbox, FormControlLabel, Tooltip, CircularProgress } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CreateIcon from '@mui/icons-material/Create';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import useAudioFiles from '../../hooks/useAudioFiles';
import useVoices from '../../hooks/useVoices';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const SentenceForm = ({ index, sentence, voices, bgVoices, preVoices, postVoices, loadingVoices, onSentenceChange, onRemoveSentence, selectedFormat }) => {    
    
    const theme = useTheme();

    const faceId = sentence.faceId || '';

    const [hasSelectedVoice, setHasSelectedVoice] = useState(false);
    const [hasSelectedBgVoice, setHasSelectedBgVoice] = useState(false);
    const [hasSelectedFace, setHasSelectedFace] = useState(false);
    const [hasSelectedPreVoice, setHasSelectedPreVoice] = useState(false);
    const [hasSelectedPostVoice, setHasSelectedPostVoice] = useState(false);
    const [filteredBgVoices, setFilteredBgVoices] = useState([]);
    const [playingAudio, setPlayingAudio] = useState(null);
    const audioRef = useRef(null);
    const [useCustomPreVoice, setUseCustomPreVoice] = useState(false);
    const [useCustomPostVoice, setUseCustomPostVoice] = useState(false);
    const [useCustomBgVoice, setUseCustomBgVoice] = useState(false);
    const [customPreVoiceDuration, setCustomPreVoiceDuration] = useState(sentence.customPreVoiceDuration || '');
    const [customPostVoiceDuration, setCustomPostVoiceDuration] = useState(sentence.customPostVoiceDuration || '');
    const [customBgVoiceDuration, setCustomBgVoiceDuration] = useState(sentence.customBgVoiceDuration || '');
    const [playingVoice, setPlayingVoice] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newSoundEffect, setNewSoundEffect] = useState({ prompt: '', duration: 1 });
    const [tempAudioUrl, setTempAudioUrl] = useState(null);
    const [currentAudioType, setCurrentAudioType] = useState(null);
    const [tempS3Key, setTempS3Key] = useState(null);
    const [useAutoDuration, setUseAutoDuration] = useState(false);
    const [generatingEffect, setGeneratingEffect] = useState(false);
    const [dropdownKey, setDropdownKey] = useState(0);
    const [newFileName, setNewFileName] = useState(''); // Nieuwe state voor het bijhouden van het nieuwe geluidseffect

    // Lokale state voor de options
    const [preVoicesOptions, setPreVoicesOptions] = useState(preVoices);
    const [postVoicesOptions, setPostVoicesOptions] = useState(postVoices);
    const [bgVoicesOptions, setBgVoicesOptions] = useState(bgVoices);

    const { getAudioUrl, generateSoundEffect, saveSoundEffect, refreshAudioFiles, checkSoundEffectStatus } = useAudioFiles(`${selectedFormat}/${currentAudioType}`);
    const { playVoiceSample, stopVoiceSample } = useVoices();

    // Synchroniseer lokale state met props
    useEffect(() => {
        setPreVoicesOptions(preVoices);
    }, [preVoices]);

    useEffect(() => {
        setPostVoicesOptions(postVoices);
    }, [postVoices]);

    useEffect(() => {
        setBgVoicesOptions(bgVoices);
    }, [bgVoices]);

    const handleBgVoiceChange = useCallback((index, filename) => {
        onSentenceChange(index, 'bgVoiceId', filename);
    }, [onSentenceChange]);

    const handleFaceChange = useCallback((index, url) => {
        onSentenceChange(index, 'faceId', url);
    }, [onSentenceChange]);

    const handlePreVoiceChange = useCallback((index, filename) => {
        onSentenceChange(index, 'preVoiceId', filename);
    }, [onSentenceChange]);

    const handlePostVoiceChange = useCallback((index, filename) => {
        onSentenceChange(index, 'postVoiceId', filename);
    }, [onSentenceChange]);

    const handlePlayAudio = async (audioType, audioId) => {
        console.log(`Attempting to play ${audioType} audio with ID:`, audioId);
        
        try {
            const fullAudioId = `${selectedFormat}/${audioType}/${audioId}`;
            const audioUrl = await getAudioUrl(fullAudioId);
            
            if (playingAudio === fullAudioId) {
                console.log('Pausing audio');
                audioRef.current.pause();
                setPlayingAudio(null);
            } else {
                if (audioRef.current) {
                    audioRef.current.pause();
                }
                console.log('Playing audio from URL:', audioUrl);
                audioRef.current = new Audio(audioUrl);
                await audioRef.current.play();
                setPlayingAudio(fullAudioId);
            }
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    };

    const handlePlayVoice = async (voiceId) => {
        if (playingVoice === voiceId) {
            await stopVoiceSample();
            setPlayingVoice(null);
        } else {
            if (playingVoice) {
                await stopVoiceSample();
            }
            await playVoiceSample(voiceId);
            setPlayingVoice(voiceId);
        }
    };

    useEffect(() => {
        if (selectedFormat) {
            const newFilteredBgVoices = bgVoicesOptions.filter(file => file.filename.startsWith(`${selectedFormat}_`));
            setFilteredBgVoices(newFilteredBgVoices);

            if (!hasSelectedBgVoice) {
                handleBgVoiceChange(index, '');
                setHasSelectedBgVoice(true);
            }
            if (!hasSelectedPreVoice) {
                handlePreVoiceChange(index, '');
                setHasSelectedPreVoice(true);
            }
            if (!hasSelectedPostVoice) {
                handlePostVoiceChange(index, '');
                setHasSelectedPostVoice(true);
            }
        } else {
            setFilteredBgVoices(bgVoicesOptions);

            if (!hasSelectedBgVoice) {
                handleBgVoiceChange(index, '');
                setHasSelectedBgVoice(true);
            }
        }
    }, [selectedFormat, bgVoicesOptions, hasSelectedBgVoice, hasSelectedPreVoice, hasSelectedPostVoice, handleBgVoiceChange, handlePreVoiceChange, handlePostVoiceChange, index]);

    useEffect(() => {
        if (!hasSelectedVoice && voices.length > 0 && !sentence.voiceId) {
            const newVoice = voices[Math.floor(Math.random() * voices.length)];
            onSentenceChange(index, 'voiceId', newVoice.voice_id);
            setHasSelectedVoice(true);
        }
    }, [voices, sentence.voiceId, selectedFormat, filteredBgVoices, hasSelectedVoice, onSentenceChange, index]);

    useEffect(() => {
        if (process.env.REACT_APP_VIDEO_ACCESS === 'yes') {
            if (!hasSelectedFace && faceId) {
                handleFaceChange(index, faceId);
                setHasSelectedFace(true);
            }
        }
    }, [faceId, hasSelectedFace, handleFaceChange, index]);

    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        };
    }, []);

    const handleCustomVoiceChange = (voiceType, value) => {
        onSentenceChange(index, `custom${voiceType}Voice`, value);
    };

    const handleCustomVoiceDurationChange = (voiceType, value) => {
        // Update de lokale state voor directe feedback
        switch(voiceType) {
            case 'Pre':
                setCustomPreVoiceDuration(value);
                break;
            case 'Post':
                setCustomPostVoiceDuration(value);
                break;
            case 'Bg':
                setCustomBgVoiceDuration(value);
                break;
            default:
                break;
        }

        // Valideer en update de echte state alleen als de waarde geldig is
        const sanitizedValue = value.replace(',', '.');
        if (sanitizedValue === '' || (/^\d*\.?\d*$/.test(sanitizedValue) && parseFloat(sanitizedValue) <= 20)) {
            onSentenceChange(index, `custom${voiceType}VoiceDuration`, sanitizedValue);
        }
    };

    const formatDuration = (value) => {
        if (value === '' || value === null) return '';
        const duration = parseFloat(value);
        return isNaN(duration) ? '' : duration.toString();
    };

    const handleAddNew = (audioType) => {
        setCurrentAudioType(audioType);
        setIsModalOpen(true);
    };

    const handleGenerateSoundEffect = async () => {
        try {
            setGeneratingEffect(true);
            const taskId = await generateSoundEffect(
                newSoundEffect.prompt,
                useAutoDuration ? null : newSoundEffect.duration,
                currentAudioType,
                selectedFormat
            );
            pollSoundEffectStatus(taskId);
        } catch (error) {
            console.error('Error generating sound effect:', error);
            setGeneratingEffect(false);
            // Toon foutmelding aan gebruiker indien nodig
        }
    };

    const pollSoundEffectStatus = async (taskId) => {
        try {
            const result = await checkSoundEffectStatus(taskId);
            console.log('Sound effect generation status:', result);

            if (result.state === 'SUCCESS') {
                setTempAudioUrl(result.result.tempUrl);
                setTempS3Key(result.result.tempS3Key);
                setGeneratingEffect(false);
            } else if (result.state === 'FAILURE') {
                console.error('Sound effect generation failed:', result.error);
                setGeneratingEffect(false);
                // Toon foutmelding aan gebruiker indien nodig
            } else {
                // Taak is nog bezig, poll opnieuw na een korte vertraging
                setTimeout(() => pollSoundEffectStatus(taskId), 2000);
            }
        } catch (error) {
            console.error('Error polling sound effect status:', error);
            setGeneratingEffect(false);
            // Toon foutmelding aan gebruiker indien nodig
        }
    };

    const handleSaveSoundEffect = async () => {
        try {
            const savedFilePath = await saveSoundEffect(
                tempS3Key,
                currentAudioType,
                selectedFormat,
                newSoundEffect.prompt,
                newSoundEffect.duration
            );
            setIsModalOpen(false);
            
            // Extraheer de bestandsnaam uit het pad
            const fileName = savedFilePath.split('/').pop();
            setNewFileName(fileName); // Stel de nieuwe bestandsnaam in
            
            // Update de respectieve options list
            if (currentAudioType === 'prevoice') {
                setPreVoicesOptions(prev => [...prev, { filename: fileName, label: fileName }]);
            } else if (currentAudioType === 'postvoice') {
                setPostVoicesOptions(prev => [...prev, { filename: fileName, label: fileName }]);
            } else if (currentAudioType === 'bgvoice') {
                setBgVoicesOptions(prev => [...prev, { filename: fileName, label: fileName }]);
            }
            
            // Selecteer het nieuwe geluidseffect in de dropdown
            onSentenceChange(index, `${currentAudioType}Id`, fileName);
            
            // Reset de state voor het nieuwe geluidseffect
            setNewSoundEffect({ prompt: '', duration: 1 });
            setTempAudioUrl(null);
            setTempS3Key(null);
            
            console.log(`New sound effect saved and selected: ${fileName}`);

            // No need to refresh if we manually update the options
            // await refreshAudioFiles();
        } catch (error) {
            console.error('Error saving sound effect:', error);
            // Toon foutmelding aan gebruiker indien nodig
        }
    };

    // Voeg een useEffect toe om het nieuwe geluidseffect te selecteren zodra het beschikbaar is
    useEffect(() => {
        if (newFileName) {
            // Bepaal de huidige opties op basis van het type audio
            const currentOptions = currentAudioType === 'prevoice' ? preVoicesOptions :
                                   currentAudioType === 'postvoice' ? postVoicesOptions :
                                   currentAudioType === 'bgvoice' ? bgVoicesOptions :
                                   [];
            
            // Zoek naar het nieuwe geluidseffect in de bijgewerkte opties
            const found = currentOptions.find(option => option.filename === newFileName);
            if (found) {
                // Selecteer het nieuwe geluidseffect in de dropdown
                onSentenceChange(index, `${currentAudioType}Id`, newFileName);
                setNewFileName(''); // Reset de nieuwe bestandsnaam state
            } else {
                // Als het nog niet gevonden is, wacht een korte tijd en probeer het opnieuw
                const timeout = setTimeout(() => {
                    const stillNotFound = currentOptions.find(option => option.filename === newFileName);
                    if (stillNotFound) {
                        onSentenceChange(index, `${currentAudioType}Id`, newFileName);
                        setNewFileName('');
                    }
                }, 1000); // Wacht 1 seconde
                return () => clearTimeout(timeout); // Reinig de timeout bij unmount of wijziging
            }
        }
    }, [preVoicesOptions, postVoicesOptions, bgVoicesOptions, newFileName, currentAudioType, index, onSentenceChange]);

    // Render de Autocomplete component
    const renderAutocomplete = (label, value, onChange, options, audioType, loading, useCustom, setUseCustom) => (
        <Autocomplete
            options={[
                { filename: 'custom', label: 'Custom' },
                { filename: 'add_new', label: 'Add New' },
                ...options
            ]}
            getOptionLabel={(option) => {
                if (option.filename === 'custom') return 'Custom';
                if (option.filename === 'add_new') return 'Add New';
                return option.filename.replace(/\.mp3$/i, '');  // Case-insensitive verwijdering van .mp3
            }}
            value={options.find(option => option.filename.toLowerCase() === value.toLowerCase()) || null}
            onChange={(event, newValue) => {
                if (newValue) {
                    if (newValue.filename === 'custom') {
                        setUseCustom(true);
                    } else if (newValue.filename === 'add_new') {
                        handleAddNew(audioType);
                    } else {
                        setUseCustom(false);
                        onChange(index, newValue.filename);
                    }
                } else {
                    onChange(index, '');
                }
            }}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <AudioFileIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    { option.filename === 'custom' ? (
                        <>
                            <CreateIcon sx={{ mr: 1, color: theme.palette.action.active }} />
                            Custom
                        </>
                    ) : option.filename === 'add_new' ? (
                        <>
                            <AddCircleOutlineIcon sx={{ mr: 1, color: theme.palette.success.main }} />
                            Add New
                        </>
                    ) : (
                        <>
                            <AudioFileIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                            {option.filename.replace(/\.mp3$/i, '')}
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePlayAudio(audioType, option.filename);
                                }}
                                sx={{ marginLeft: 'auto' }}
                            >
                                {playingAudio === `${selectedFormat}/${audioType}/${option.filename}` ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                        </>
                    )}
                </li>
            )}
        />
    );

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: theme.palette.background.paper,
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
    };

    return (
        <Box className="sentenceForm" sx={{ 
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            borderRadius: '8px',
            padding: '16px',
            boxShadow: theme.shadows[3],
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box className="preVoiceSection">
                        {renderAutocomplete(
                            'Audio voor fragment',
                            sentence.preVoiceId,
                            (idx, value) => onSentenceChange(idx, 'preVoiceId', value),
                            preVoicesOptions,
                            'prevoice',
                            loadingVoices,
                            useCustomPreVoice,
                            setUseCustomPreVoice
                        )}
                    </Box>
                    {useCustomPreVoice && (
                        <Box mt={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        label="Custom Pre-voice Prompt"
                                        value={sentence.customPreVoice || ''}
                                        onChange={(e) => handleCustomVoiceChange('Pre', e.target.value)}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: theme.palette.text.primary,
                                            },
                                            '& .MuiInputBase-input': {
                                                color: theme.palette.text.primary,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Duration (sec)"
                                        value={customPreVoiceDuration}
                                        onChange={(e) => handleCustomVoiceDurationChange('Pre', e.target.value)}
                                        onBlur={() => setCustomPreVoiceDuration(formatDuration(sentence.customPreVoiceDuration))}
                                        inputProps={{ inputMode: 'decimal' }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: theme.palette.text.primary,
                                            },
                                            '& .MuiInputBase-input': {
                                                color: theme.palette.text.primary,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>

                <Grid item xs={12} md={8}>
                    <TextField
                        label={`Fragment ${index + 1} (${sentence.type || 'Onbekend type'})`}
                        multiline
                        rows={5}
                        fullWidth
                        value={sentence.text}
                        onChange={(e) => onSentenceChange(index, 'text', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.text.primary,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.text.primary,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.text.primary,
                            },
                            '& .MuiInputBase-input': {
                                color: theme.palette.text.primary,
                            },
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Box className="voiceControls">
                        <FormControl fullWidth>
                            <Autocomplete
                                options={voices}
                                getOptionLabel={(option) => option.name}
                                value={voices.find(voice => voice.voice_id === sentence.voiceId) || null}
                                onChange={(event, newValue) => {
                                    onSentenceChange(index, 'voiceId', newValue ? newValue.voice_id : '');
                                }}
                                isOptionEqualToValue={(option, value) => option.voice_id === value.voice_id}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        label="Stem" 
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <RecordVoiceOverIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                                            ),
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <RecordVoiceOverIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                                        {option.name}
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handlePlayVoice(option.voice_id);
                                            }}
                                            sx={{ marginLeft: 'auto' }}
                                        >
                                            {playingVoice === option.voice_id ? <PauseIcon /> : <PlayArrowIcon />}
                                        </IconButton>
                                    </li>
                                )}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: theme.palette.text.primary,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: theme.palette.text.primary,
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: theme.palette.primary.main,
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: theme.palette.text.primary,
                                    },
                                    '& .MuiInputBase-input': {
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            />
                        </FormControl>
                    </Box>

                    <Box className="bgVoiceSection" mt={2}>
                        {renderAutocomplete(
                            'Achtergrondgeluid',
                            sentence.bgVoiceId,
                            (idx, value) => handleBgVoiceChange(idx, value),
                            bgVoicesOptions,
                            'bgvoice',
                            loadingVoices,
                            useCustomBgVoice,
                            setUseCustomBgVoice
                        )}
                    </Box>
                    {useCustomBgVoice && (
                        <Box mt={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        label="Custom Background Voice Prompt"
                                        value={sentence.customBgVoice || ''}
                                        onChange={(e) => handleCustomVoiceChange('Bg', e.target.value)}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: theme.palette.text.primary,
                                            },
                                            '& .MuiInputBase-input': {
                                                color: theme.palette.text.primary,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Duration (sec)"
                                        value={customBgVoiceDuration}
                                        onChange={(e) => handleCustomVoiceDurationChange('Bg', e.target.value)}
                                        onBlur={() => setCustomBgVoiceDuration(formatDuration(sentence.customBgVoiceDuration))}
                                        inputProps={{ inputMode: 'decimal' }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: theme.palette.text.primary,
                                            },
                                            '& .MuiInputBase-input': {
                                                color: theme.palette.text.primary,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Box className="postVoiceSection">
                        {renderAutocomplete(
                            'Audio na fragment',
                            sentence.postVoiceId,
                            (idx, value) => handlePostVoiceChange(idx, value),
                            postVoicesOptions,
                            'postvoice',
                            loadingVoices,
                            useCustomPostVoice,
                            setUseCustomPostVoice
                        )}
                    </Box>
                    {useCustomPostVoice && (
                        <Box mt={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        label="Custom Post-voice Prompt"
                                        value={sentence.customPostVoice || ''}
                                        onChange={(e) => handleCustomVoiceChange('Post', e.target.value)}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: theme.palette.text.primary,
                                            },
                                            '& .MuiInputBase-input': {
                                                color: theme.palette.text.primary,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Duration (sec)"
                                        value={customPostVoiceDuration}
                                        onChange={(e) => handleCustomVoiceDurationChange('Post', e.target.value)}
                                        onBlur={() => setCustomPostVoiceDuration(formatDuration(sentence.customPostVoiceDuration))}
                                        inputProps={{ inputMode: 'decimal' }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: theme.palette.text.primary,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: theme.palette.text.primary,
                                            },
                                            '& .MuiInputBase-input': {
                                                color: theme.palette.text.primary,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
                        Genereer Nieuw Geluidseffect
                    </Typography>
                    <TextField
                        fullWidth
                        label="Beschrijving van het geluidseffect"
                        value={newSoundEffect.prompt}
                        onChange={(e) => setNewSoundEffect({ ...newSoundEffect, prompt: e.target.value })}
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.text.primary,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.text.primary,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.text.primary,
                            },
                            '& .MuiInputBase-input': {
                                color: theme.palette.text.primary,
                            },
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={useAutoDuration}
                                onChange={(e) => setUseAutoDuration(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Automatische duur"
                    />
                    <Tooltip title="Als deze optie is aangevinkt, zal ElevenLabs automatisch de optimale duur voor het geluidseffect bepalen op basis van de beschrijving.">
                        <HelpOutlineIcon sx={{ ml: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {!useAutoDuration && (
                        <TextField
                            fullWidth
                            label="Duur (seconden)"
                            type="number"
                            value={newSoundEffect.duration}
                            onChange={(e) => setNewSoundEffect({ ...newSoundEffect, duration: Math.max(0.5, Math.min(22, parseFloat(e.target.value))) })}
                            inputProps={{ min: 0.5, max: 22, step: 0.1 }}
                            margin="normal"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.text.primary,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.text.primary,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.text.primary,
                                },
                                '& .MuiInputBase-input': {
                                    color: theme.palette.text.primary,
                                },
                            }}
                        />
                    )}
                    <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                        {useAutoDuration 
                            ? "ElevenLabs zal de optimale duur bepalen op basis van de beschrijving."
                            : "De duur moet tussen 0,5 en 22 seconden liggen."}
                    </Typography>
                    <Button 
                        variant="contained" 
                        onClick={handleGenerateSoundEffect}
                        fullWidth
                        sx={{ mt: 2 }}
                        disabled={generatingEffect}
                    >
                        {generatingEffect ? (
                            <>
                                <CircularProgress size={24} sx={{ mr: 1 }} />
                                Genereren...
                            </>
                        ) : (
                            'Genereer Geluidseffect'
                        )}
                    </Button>
                    {tempAudioUrl && (
                        <Box mt={2}>
                            <audio src={tempAudioUrl} controls style={{ width: '100%' }} />
                            <Box mt={2} display="flex" justifyContent="space-between">
                                <Button variant="contained" color="primary" onClick={handleSaveSoundEffect}>
                                    Opslaan
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={() => setTempAudioUrl(null)}>
                                    Verwijderen
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default SentenceForm;