import React from 'react';
import { Box, keyframes, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Definieer de wave animatie
const wave = keyframes`
  0%, 100% {
    d: path("M0,40 Q12.5,20 25,40 T50,40");
  }
  50% {
    d: path("M0,40 Q12.5,60 25,40 T50,40");
  }
`;

// Definieer de fadeIn animatie
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AudioWaveLoader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'transparent', // Transparante achtergrond
        overflow: 'hidden',
      }}
    >
      {/* Laadtekst met semi-transparante achtergrond voor leesbaarheid */}
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          animation: `${fadeIn} 1s ease-out`,
          color: '#ffffff',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: '10px 20px',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        }}
      >
        Stemmy aan het laden...
      </Typography>

      {/* SVG met lettervormen en geanimeerde M's */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '200px',
          width: '80%',
          maxWidth: '800px',
          position: 'relative',
        }}
      >
        <svg width="100%" height="200" viewBox="0 0 800 200">
          <defs>
            <linearGradient id="neonGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#ff00ff" />
              <stop offset="25%" stopColor="#00ffff" />
              <stop offset="50%" stopColor="#ff9400" />
              <stop offset="75%" stopColor="#39ff14" />
              <stop offset="100%" stopColor="#ff3131" />
            </linearGradient>
          </defs>

          {/* S */}
          <path
            d="M50,50 Q50,25 75,25 L100,25 Q125,25 125,50 L125,75 Q125,100 100,100 L75,100 Q50,100 50,125 L50,150 Q50,175 75,175 L100,175 Q125,175 125,150"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out` }}
          />

          {/* T */}
          <path
            d="M175,25 L275,25 M225,25 L225,175"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out 0.2s` }}
          />

          {/* E */}
          <path
            d="M325,25 L425,25 M325,100 L400,100 M325,175 L425,175 M325,25 L325,175"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out 0.4s` }}
          />

          {/* Eerste M */}
          {/* Verticale lijnen */}
          <path
            d="M475,25 L475,175"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out 0.6s` }}
          />
          <path
            d="M575,25 L575,175"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out 0.6s` }}
          />
          {/* Middenlijn */}
          <path
            d="M475,25 L525,100 L575,25"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out 0.6s` }}
          />
          {/* Geanimeerde waveform voor de eerste M */}
          <path
            d="M475,100 Q500,60 525,100 T575,100"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{
              animation: `${wave} 1.5s ease-in-out infinite`,
            }}
          />

          {/* Tweede M */}
          {/* Verticale lijnen */}
          <path
            d="M625,25 L625,175"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out 0.8s` }}
          />
          <path
            d="M725,25 L725,175"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out 0.8s` }}
          />
          {/* Middenlijn */}
          <path
            d="M625,25 L675,100 L725,25"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out 0.8s` }}
          />
          {/* Geanimeerde waveform voor de tweede M */}
          <path
            d="M625,100 Q650,60 675,100 T725,100"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{
              animation: `${wave} 1.5s ease-in-out infinite 0.1s`,
            }}
          />

          {/* Y */}
          <path
            d="M775,25 L725,100 M775,25 L825,100 M775,100 L775,175"
            fill="none"
            stroke="url(#neonGradient)"
            strokeWidth="8"
            style={{ animation: `${fadeIn} 1s ease-out 1s` }}
          />
        </svg>
      </Box>
    </Box>
  );
};

export default AudioWaveLoader;