import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Grid, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CreateIcon from '@mui/icons-material/Create';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import useAudioFiles from '../../hooks/useAudioFiles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

function AudioSettingsForm({ audiosettings, setAudioSettings, selectedFormat, setSelectedFormat, tags }) {
  const { audioFiles: introFiles, loading: loadingIntros } = useAudioFiles(`${selectedFormat}/intros`);
  const { audioFiles: outroFiles, loading: loadingOutros } = useAudioFiles(`${selectedFormat}/outros`);
  const { audioFiles: bgaudioFiles, loading: loadingBgaudio } = useAudioFiles(`${selectedFormat}/bgaudio`);
  const { getAudioUrl } = useAudioFiles();

  const handleSettingChange = useCallback((setting, value) => {
    setAudioSettings(prevSettings => ({
      ...prevSettings,
      [setting]: value
    }));
  }, [setAudioSettings]);

  const [playingAudio, setPlayingAudio] = useState(null);
  const audioRef = useRef(null);

  const handlePlayAudio = async (audioType, audioFileName) => {
    console.log(`Attempting to play ${audioType} audio:`, audioFileName);
    
    try {
      const fullAudioId = `${selectedFormat}/${audioType}/${audioFileName}`;
      const audioUrl = await getAudioUrl(fullAudioId);

      if (playingAudio === fullAudioId) {
        console.log('Pausing audio');
        audioRef.current.pause();
        setPlayingAudio(null);
      } else {
        if (audioRef.current) {
          audioRef.current.pause();
        }
        console.log('Playing audio from URL:', audioUrl);
        audioRef.current = new Audio(audioUrl);
        audioRef.current.play().catch(e => console.error('Error playing audio:', e));
        setPlayingAudio(fullAudioId);
      }
    } catch (error) {
      console.error('Error getting audio URL:', error);
    }
  };

  const renderMenuItem = (file, audioType) => (
    <MenuItem key={file.filename} value={file.filename} sx={{ display: 'flex', alignItems: 'center' }}>
      <AudioFileIcon sx={{ mr: 1, color: 'primary.main' }} />
      {file.filename.replace(/\.mp3$/, '')}
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          handlePlayAudio(audioType, file.filename);
        }}
        sx={{ marginLeft: 'auto' }}
      >
        {playingAudio === `${selectedFormat}/${audioType}/${file.filename}` ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
    </MenuItem>
  );

  const renderSelect = (label, value, onChange, options, audioType, loading) => (
    <FormControl fullWidth>
      <InputLabel id={`${audioType}-label`}>{label}</InputLabel>
      <Select
        labelId={`${audioType}-label`}
        value={value}
        onChange={onChange}
        IconComponent={({ className }) => <ArrowDropDownIcon className={className} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AudioFileIcon sx={{ mr: 1, color: 'primary.main' }} />
            {selected.replace(/\.mp3$/, '')}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250,
            },
          },
        }}
      >
        <MenuItem value="">Geen</MenuItem>
        <MenuItem 
          value="custom" 
          sx={{ 
            color: 'text.primary',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <CreateIcon sx={{ mr: 1, color: 'action.active' }} />
          Custom
        </MenuItem>
        <MenuItem 
          value="add_new" 
          sx={{ 
            color: 'text.primary',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1, color: 'success.main' }} />
          Add New
        </MenuItem>
        {loading ? (
          <MenuItem disabled>Laden...</MenuItem>
        ) : (
          options.map((file) => renderMenuItem(file, audioType))
        )}
      </Select>
    </FormControl>
  );

  useEffect(() => {
    if (selectedFormat) {
        // Randomly select new audio files when the format changes or new files are loaded
        const newIntro = introFiles.length > 0 ? introFiles[Math.floor(Math.random() * introFiles.length)] : null;
        const newOutro = outroFiles.length > 0 ? outroFiles[Math.floor(Math.random() * outroFiles.length)] : null;
        const newBgAudio = bgaudioFiles.length > 0 ? bgaudioFiles[Math.floor(Math.random() * bgaudioFiles.length)] : null;

        handleSettingChange('intro', newIntro ? newIntro.filename : '');
        handleSettingChange('outro', newOutro ? newOutro.filename : '');
        handleSettingChange('bgaudio', newBgAudio ? newBgAudio.filename : '');
    }
  }, [selectedFormat, introFiles, outroFiles, bgaudioFiles, handleSettingChange]);

  useEffect(() => {
    handleSettingChange('format', selectedFormat || '');
  }, [selectedFormat, handleSettingChange]);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  const theme = useTheme();

  return (
    <Box className="AudioSettingsForm">
      <Typography 
        variant="h5" 
        sx={{
          marginBottom: '20px',
          fontFamily: "'Roboto Slab', serif",
          color: theme.palette.text.primary,
          fontWeight: 'bold'
        }}
      >
        3. Kies intro, achtergrond audio en outro
      </Typography>
      
      <Grid container spacing={2}>

        {/* Intro select */}
        <Grid item xs={12} md={4}>
          {renderSelect(
            'Intro (start van je audiobestand)',
            audiosettings.intro,
            (e) => handleSettingChange('intro', e.target.value),
            introFiles,
            'intros',
            loadingIntros
          )}
        </Grid>

        {/* BGaudio select */}
        <Grid item xs={12} md={4}>
          {renderSelect(
            'Achtergrond (audio op de achtergrond)',
            audiosettings.bgaudio,
            (e) => handleSettingChange('bgaudio', e.target.value),
            bgaudioFiles,
            'bgaudio',
            loadingBgaudio
          )}
        </Grid>
        
        {/* Outro select */}
        <Grid item xs={12} md={4}>
          {renderSelect(
            'Outro (einde van je audiobestand)',
            audiosettings.outro,
            (e) => handleSettingChange('outro', e.target.value),
            outroFiles,
            'outros',
            loadingOutros
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default AudioSettingsForm;