import React from 'react';
import { useTheme } from './theme/ThemeContext';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AudioForm from './components/AudioForm/AudioForm';
import InstructionsModal from './components/InstructionsModal/InstructionsModal';

function AppContent() {
  const { currentTheme, toggleTheme } = useTheme();
  const [instructionsOpen, setInstructionsOpen] = React.useState(false);

  const handleInstructionsOpen = () => {
    setInstructionsOpen(true);
  };

  const handleInstructionsClose = () => {
    setInstructionsOpen(false);
  };

  return (
    <>
      <Header 
        toggleTheme={toggleTheme} 
        currentTheme={currentTheme} 
        handleInstructionsOpen={handleInstructionsOpen}
      />
      <main>
        <AudioForm />
      </main>
      <Footer />
      <InstructionsModal 
        open={instructionsOpen} 
        onClose={handleInstructionsClose} 
      />
    </>
  );
}

export default AppContent;