import React from 'react';
import { ThemeProvider } from './theme/ThemeContext';
import AppContent from './AppContent';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
      <ThemeProvider>
        <CssBaseline />
        <AppContent />
      </ThemeProvider>
    </Router>
  );
}

export default App;