import React, {useState, useEffect, useCallback, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTheme } from '../../theme/ThemeContext';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import useVoices from '../../hooks/useVoices';
import useGenerateMultipleVoices from '../../hooks/useGenerateMultipleVoices';
import SentenceForm from '../SentenceForm/SentenceForm';
import useSentenceForm from '../../hooks/useSentenceForm';
import AudioSettingsForm from '../AudioSettingsForm/AudioSettingsForm';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ChatForm from '../ChatForm/ChatForm';
import useAudioFiles from '../../hooks/useAudioFiles';
import CloseIcon from '@mui/icons-material/Close';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import RadioIcon from '@mui/icons-material/Radio';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack, keyframes, Grid, Fab } from '@mui/material';
import AudioWaveLoader from '../AudioWaveLoader/AudioWaveLoader';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip } from '@mui/material';
import formats from '../../formats.json';
import * as MuiIcons from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
/*import { Button as ShadcnButton } from "../ui/button";

/**
     * AudioForm Component
     * 
     * This component handles the creation and management of audio content using various voices.
     * It integrates with ElevenLabs API for voice generation and provides a user interface for
     * creating, editing, and arranging audio sentences.
     * 
     * Key Features:
     * - Voice selection from available ElevenLabs voices
     * - Sentence management (add, remove, update, reorder)
     * - Audio settings configuration (intro, outro, background audio)
     * - Integration with chat API for script generation
     * - Audio generation and playback
     * 
     * State Management:
     * - voices: Available ElevenLabs voices
     * - sentences: Current list of sentences to be converted to audio
     * - audioSettings: Configuration for intro, outro, and background audio
     * - selectedFormat: Selected format for voice generation
     * - isGenerating: Flag indicating if audio is being generated
     * - hasGenerated: Flag indicating if audio has been generated
     * - chatApiResponse: Response from the chat API for script generation
     * 
     * Key Functions:
     * - handleSubmit: Initiates the audio generation process
     * - handleChatApiResponse: Processes the response from the chat API
     * - onDragEnd: Handles reordering of sentences
     * - handleSettingChange: Updates audio settings
     * 
     * Dependencies:
     * - useVoices: Custom hook for fetching available voices
     * - useGenerateMultipleVoices: Custom hook for generating audio from multiple voices
     * - useSentenceForm: Custom hook for managing sentences
     * - useAudioFiles: Custom hook for managing background audio files
     */
const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
`;

function AudioForm() {
    const location = useLocation();
    const { currentTheme, toggleTheme, setThemeByFormat } = useTheme();

    const { voices, loading: voicesLoading } = useVoices();

   // const [selectedFormat, setSelectedFormat] = useState('');
   // const tags = [...new Set(voices.flatMap(voice => voice.labels ? Object.values(voice.labels) : []))];
    const tags = [...new Set(voices
    .filter(voice => voice.labels && 'tag1' in voice.labels)
    .flatMap(voice => Object.values(voice.labels))
    )];
    
    const [selectedFormat, setSelectedFormat] = useState('');
    const { audioUrl, videoUrl, generateVoices, loading, error, taskStatus } = useGenerateMultipleVoices(); 
    const { sentences, setSentences, replaceSentences, addSentence, removeSentence, updateSentence} = useSentenceForm(voices, selectedFormat);
    const [isGenerating, setIsGenerating] = useState(false);
    const [hasGenerated, setHasGenerated] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [audiosettings, setAudioSettings] = useState({
        intro: '',
        outro: '',
        bgaudio: ''
    });

    const [preVoices, setPreVoices] = useState([]);
    const [postVoices, setPostVoices] = useState([]);
    const [bgVoices, setBgVoices] = useState([]);
    const [loadingVoices, setLoadingVoices] = useState(true);

    // Gebruik useAudioFiles hook voor elk type audio
    const { audioFiles: preVoiceFiles, loading: loadingPreVoices } = useAudioFiles(`${selectedFormat}/prevoice`);
    const { audioFiles: postVoiceFiles, loading: loadingPostVoices } = useAudioFiles(`${selectedFormat}/postvoice`);
    const { audioFiles: bgVoiceFiles, loading: loadingBgVoices } = useAudioFiles(`${selectedFormat}/bgvoice`);

    useEffect(() => {
        if (selectedFormat) {
            setPreVoices(preVoiceFiles);
            setPostVoices(postVoiceFiles);
            setBgVoices(bgVoiceFiles);
            setLoadingVoices(loadingPreVoices || loadingPostVoices || loadingBgVoices);
        }
    }, [selectedFormat, preVoiceFiles, postVoiceFiles, bgVoiceFiles, loadingPreVoices, loadingPostVoices, loadingBgVoices]);

    // Drag and drop
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(sentences);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSentences(items);
    };

    const handleSettingChange = (setting, value) => {
        setAudioSettings(prevSettings => ({
            ...prevSettings,
            [setting]: value
        }));
    };

    const [imageUrl, setImageUrl] = useState('');

    const [videoEnabled, setVideoEnabled] = useState(false);
    const showVideoOption = location.pathname.includes('/shows');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsGenerating(true);
        setHasGenerated(false);
        await generateVoices(sentences, audiosettings, imageUrl, videoEnabled);
    };
    

    useEffect(() => {
        if (taskStatus === 'Task completed') {
            setIsGenerating(false);
            setHasGenerated(true);
            if (audioUrl || videoUrl) {
                setOpen(true);
            }
        } else if (taskStatus === 'Task in progress') {
            setIsGenerating(true);
        }
    }, [taskStatus, audioUrl, videoUrl]);

    useEffect(() => {
        if (error) {
            setIsGenerating(false);
            setHasGenerated(false);
            setOpenSnackbar(true); // Open the Snackbar
        }
    }, [error]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const [chatApiResponse, setChatApiResponse] = useState('');
    const [hasGeneratedScript, setHasGeneratedScript] = useState(false);
    const [scriptAction, setScriptAction] = useState('replace');
    const [lastProcessedResponseId, setLastProcessedResponseId] = useState(null);

    const handleChatApiResponse = useCallback((response) => {
        console.log("API response received in AudioForm:", response);
        setChatApiResponse(response);

        if (!response || !response.script || response.id === lastProcessedResponseId) {
            console.error('chatApiResponse is null, heeft geen script, of is al verwerkt.');
            return;
        }

        const voiceNameToId = voices.reduce((acc, voice) => {
            acc[voice.name] = voice.voice_id;
            return acc;
        }, {});

        const newSentences = response.script.flatMap(segment => 
            segment.content.map(content => {
                return {
                    text: content.text, 
                    voiceId: voiceNameToId[content.voice] || '',
                    bgVoiceId: content.bgvoice || '',
                    preVoiceId: content.prevoice || '',
                    postVoiceId: content.postvoice || '',
                    type: segment.type || ''
                }
            })
        );

        if (scriptAction === 'replace') {
            replaceSentences(newSentences);
        } else {
            setSentences(prevSentences => [...prevSentences, ...newSentences]);
        }
        
        setHasGeneratedScript(true);
        setLastProcessedResponseId(response.id);

        // Voeg deze regel toe om de imageUrl bij te werken
        if (response.image_url) {
            setImageUrl(response.image_url);
        }
    }, [voices, scriptAction, replaceSentences, setSentences, lastProcessedResponseId]);

    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (audioUrl) {
        setOpen(true);
      }
    }, [audioUrl]);
  
    const handleClose = () => {
      setOpen(false);
    };

    const [instructionsOpen, setInstructionsOpen] = React.useState(false);

    const handleInstructionsOpen = () => {
        setInstructionsOpen(true);
    };

    const handleInstructionsClose = () => {
        setInstructionsOpen(false);
    };

    const chatFormRef = useRef(null);

    const handleFormatChange = (newFormat) => {
        setSelectedFormat(newFormat);
        if (setThemeByFormat) {
            setThemeByFormat(newFormat);
        }
        // Focus het input veld in ChatForm
        if (chatFormRef.current && chatFormRef.current.focusInput) {
            chatFormRef.current.focusInput();
        }
    };

    const shareUrl = videoUrl || audioUrl;
    const shareText = "Check dit Stemmy fragment!";

    const [copied, setCopied] = useState(false);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareUrl).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    const filteredFormats = formats.formats.filter(format => 
        format.availableOn.includes('all') || format.availableOn.includes(location.pathname)
    );

    
    
    return (
        <Container className="container" sx={{ my: 1 }}> {/* Added margin top and bottom */}
            {voicesLoading ? (
                <AudioWaveLoader />
            ) : (
                <>
                    <Stack spacing={3}>
                        <Paper className="paper" elevation={3}>
                            <Box className="typographyHeader">
                                <Typography variant="h5">
                                    1. Kies een format
                                </Typography>
                            </Box>
                            <Grid container spacing={2} justifyContent="center">
                                {filteredFormats.map((format) => {
                                    const IconComponent = MuiIcons[format.icon];
                                    return (
                                        <Grid item key={format.id}>
                                            <IconButton
                                                onClick={() => handleFormatChange(format.id)}
                                                sx={{
                                                    width: 100,
                                                    height: 100,
                                                    border: selectedFormat === format.id ? '2px solid' : 'none',
                                                    borderColor: 'primary.main',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <IconComponent 
                                                    sx={{ 
                                                        fontSize: 40,
                                                        color: selectedFormat === format.id ? 'primary.main' : format.iconColor
                                                    }} 
                                                />
                                                <Typography variant="caption" align="center">
                                                    {format.title}
                                                </Typography>
                                            </IconButton>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Paper>

                        <Paper className="paper" elevation={3}>
                            <Box className="typographyHeader">
                                <Typography variant="h5">
                                    2. Genereer een script
                                </Typography>
                            </Box>
                            <ChatForm 
                                ref={chatFormRef}  // Voeg deze regel toe
                                onChatResponse={handleChatApiResponse} 
                                chatApiResponse={chatApiResponse}
                                selectedFormat={selectedFormat}
                                hasGeneratedScript={hasGeneratedScript}
                                scriptAction={scriptAction}
                                setScriptAction={setScriptAction}
                                setImageUrl={setImageUrl}
                            />
                        </Paper>

                        <Paper className="paper" elevation={3}>
                            <AudioSettingsForm 
                                audiosettings={audiosettings} 
                                setAudioSettings={setAudioSettings} 
                                handleSettingChange={handleSettingChange}
                                tags={tags}
                                selectedFormat={selectedFormat}
                                setSelectedFormat={setSelectedFormat}
                            />
                        </Paper>

                        <Paper className="paper" elevation={3}>
                            <Box className="typographyHeader">
                                <Typography variant="h5">
                                    4. Pas fragmenten aan of voeg er extra toe
                                </Typography>
                            </Box>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="dropsentences">
                                    {(provided) => (
                                        <div 
                                            {...provided.droppableProps} 
                                            ref={provided.innerRef}
                                        >
                                            {sentences.map((sentence, index) => (
                                                <Draggable 
                                                    key={`sentence-${index}`} 
                                                    draggableId={`sentence-${index}`} 
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            className="draggableContainer"
                                                        >
                                                            <div {...provided.dragHandleProps} 
                                                                className="dragHandleContainer">
                                                                <DragHandleIcon />
                                                            </div>
                                                            <SentenceForm
                                                                index={index}
                                                                sentence={sentence}
                                                                voices={voices.filter(voice => {
                                                                    if (selectedFormat === '') return true;
                                                                    if (!voice.labels) return false;
                                                                    return Object.entries(voice.labels).some(([key, value]) => key.startsWith('tag') && value === selectedFormat);
                                                                })}
                                                                onSentenceChange={updateSentence}
                                                                onRemoveSentence={removeSentence}
                                                                selectedFormat={selectedFormat}
                                                                bgVoices={bgVoices}
                                                                preVoices={preVoices}
                                                                postVoices={postVoices}
                                                                loadingVoices={loadingVoices}
                                                                faceId={sentence.faceId}
                                                            />
                                                            <IconButton
                                                                onClick={() => removeSentence(index)}
                                                                aria-label="delete"
                                                                className="deleteButton"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Fab
                                    color="secondary"
                                    aria-label="add"
                                    onClick={addSentence}
                                    disabled={loading}
                                >
                                    <AddIcon />
                                </Fab>
                            </Box>
                        </Paper>
                    </Stack>

                    {/* Wrap de video switch en de Genereer button in een Box voor betere uitlijning */}
                    <Box sx={{ 
                        mt: 3, 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        gap: 2  // Voegt wat ruimte toe tussen de switch en de button
                    }}>
                        {showVideoOption && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={videoEnabled}
                                        onChange={(e) => setVideoEnabled(e.target.checked)}
                                        name="videoEnabled"
                                        color="primary"
                                    />
                                }
                                label="Video genereren"
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'primary.main',
                                    borderRadius: 1,
                                    padding: '4px 8px',
                                    backgroundColor: 'background.paper',
                                    '& .MuiFormControlLabel-label': {
                                        color: 'text.primary',
                                    }
                                }}
                            />
                        )}

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={loading || isGenerating}
                            className="submitButton"
                            onClick={handleSubmit}
                            startIcon={<CreateIcon />}
                            sx={{
                                backgroundColor: isGenerating ? 'grey.500' : 'primary.main',
                                color: 'white',
                                '&:disabled': {
                                    backgroundColor: 'grey.500',
                                    color: 'white',
                                },
                                padding: '12px 24px',
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                                width: '100%',
                                maxWidth: '300px',
                            }}
                        >
                            {loading || isGenerating ? (
                                <>
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: 'white',
                                            animation: `${pulse} 1s infinite ease-in-out`,
                                            mr: 1,
                                        }}
                                    />
                                    {taskStatus === 'Task in progress' ? 'Stemmy aan het maken...' : 'Taak wordt verwerkt...'}
                                </>
                            ) : hasGenerated ? 'Genereer nog een Stemmy' : 'Genereer Stemmy'}
                        </Button>
                    </Box>
                </>
            )}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    Er is een fout opgetreden: {error}
                </Alert>
            </Snackbar>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box 
                        sx={{ 
                            position: 'absolute', 
                            top: '50%', 
                            left: '50%', 
                            transform: 'translate(-50%, -50%)', 
                            bgcolor: 'background.paper', 
                            boxShadow: 24, 
                            p: 4,
                            borderRadius: '10px',
                            width: { xs: '90%', sm: '80%', md: '70%' },
                            maxWidth: '800px',
                            maxHeight: '90vh',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <IconButton 
                            onClick={handleClose}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box className="typographyHeader">
                            <Typography variant="h4" component="h2" gutterBottom>
                                Stemmy gereed!
                            </Typography>
                        </Box>
                        
                        {videoUrl ? (
                            <video controls src={videoUrl} style={{ width: '100%', maxWidth: '720px', marginBottom: '20px' }} />
                        ) : (
                            <AudioPlayer
                                autoPlay
                                src={audioUrl}
                                onPlay={e => console.log("onPlay")}
                                style={{ width: '100%', maxWidth: '500px', marginBottom: '20px' }}
                            />
                        )}
                        <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2} mb={3}>
                            <Button 
                                variant="contained" 
                                startIcon={<AudioFileIcon />}
                                href={audioUrl}
                                download
                            >
                                Download Audio
                            </Button>
                            {videoUrl && (
                                <Button 
                                    variant="contained" 
                                    startIcon={<VideoFileIcon />}
                                    href={videoUrl}
                                    download
                                >
                                    Download Video
                                </Button>
                            )}
                        </Box>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<WhatsAppIcon />}
                                onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent(shareText + ' ' + shareUrl)}`, '_blank')}
                            >
                                Deel op WhatsApp
                            </Button>
                            <Tooltip title={copied ? "Gekopieerd!" : "Kopieer link"}>
                                <IconButton onClick={handleCopyLink}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Container>

        
    );
}

export default AudioForm;