import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Box, useTheme, Button, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import logo from '../../assets/stemmy.svg';

const Header = ({ toggleTheme, currentTheme, handleInstructionsOpen }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();

  const menuItems = ['Home', 'Over ons', 'Contact'];

  return (
    <AppBar position="static" elevation={0} sx={{ 
      backgroundColor: 'transparent', 
      backdropFilter: 'blur(10px)',
      mb: 4
    }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          opacity: 1,
          transform: 'translateX(0)',
          transition: 'opacity 0.5s, transform 0.5s',
        }}>
          <img src={logo} alt="Stemmy Logo" style={{ height: '40px', marginRight: '10px', alignContent: 'center', top: '5px' }} />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleInstructionsOpen} color="inherit" sx={{ mr: 1 }}>
            <HelpOutlineIcon />
          </IconButton>
          <IconButton onClick={toggleTheme} color="inherit" sx={{ mr: 2 }}>
            {currentTheme === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={{ width: 250, height: '100%', backgroundColor: theme.palette.background.default }}
          role="presentation"
          onClick={() => setDrawerOpen(false)}
          onKeyDown={() => setDrawerOpen(false)}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            {menuItems.map((text) => (
              <ListItem button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Header;