import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { TextField, Button, Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, keyframes, Typography, Alert } from '@mui/material';
import useChat from '../../hooks/useChat';
import CreateIcon from '@mui/icons-material/Create';
import formats from '../../formats.json';

const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
`;

const ChatForm = forwardRef(({ onChatResponse, selectedFormat, hasGeneratedScript, scriptAction, setScriptAction, setImageUrl }, ref) => {
    const [input, setInput] = useState('');
    const [localImageUrl, setLocalImageUrl] = useState('');
    const { response, chat, analyzeImageAndText, loading, error, taskStatus } = useChat();
    const [errorMessage, setErrorMessage] = useState('');
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        focusInput: () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }));

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleImageUrlChange = (event) => {
        const newImageUrl = event.target.value;
        setLocalImageUrl(newImageUrl);
        setImageUrl(newImageUrl);
    };

    useEffect(() => {
        if (response && taskStatus === 'Task completed') {
            onChatResponse(response);
        }
    }, [response, taskStatus, onChatResponse]);

    const handleButtonClick = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        if (localImageUrl) {
            await analyzeImageAndText(input, localImageUrl, selectedFormat);
        } else {
            await chat(input, selectedFormat);
        }
    };

    useEffect(() => {
        if (error) {
            let friendlyMessage = 'Er is een fout opgetreden. Probeer het opnieuw.';
            if (error.includes('Invalid image')) {
                friendlyMessage = 'De opgegeven afbeelding is ongeldig. Controleer of de URL correct is en of de afbeelding toegankelijk is.';
            } else if (error.includes('invalid_request_error')) {
                friendlyMessage = 'Er is een probleem met je verzoek. Controleer de ingevoerde gegevens en probeer het opnieuw.';
            }
            setErrorMessage(friendlyMessage);
        }
    }, [error]);

    const selectedFormatObj = formats.formats.find(f => f.id === selectedFormat);
    const examples = selectedFormatObj ? selectedFormatObj.examples : [];

    return (
        <Box
            component="form"
            id="chat-form"
            onSubmit={handleButtonClick}
            display="flex"
            flexDirection="column"
            gap={2}
        >
            <TextField
                id="input-field"
                label={hasGeneratedScript ? "Wat wil je toevoegen aan het script?" : "Wat wil je in een script gieten?"}
                multiline
                placeholder={examples.length > 0 
                    ? `Bijvoorbeeld:\n${examples.join('\n')}`
                    : "Benoem een onderwerp of deel een hele lap aan data/tekst."}
                rows={6}
                value={input}
                onChange={handleInputChange}
                variant="outlined"
                disabled={loading}
                inputRef={inputRef}
            />

            <TextField
                id="image-url-field"
                label="Afbeelding URL (optioneel)"
                placeholder="Voer de URL van een afbeelding in"
                value={localImageUrl}
                onChange={handleImageUrlChange}
                variant="outlined"
                disabled={loading}
            />

            {hasGeneratedScript && (
                <FormControl component="fieldset">
                    <FormLabel component="legend">Actie voor nieuw script</FormLabel>
                    <RadioGroup
                        aria-label="script-action"
                        name="script-action"
                        value={scriptAction}
                        onChange={(e) => setScriptAction(e.target.value)}
                    >
                        <FormControlLabel value="replace" control={<Radio />} label="Vervang bestaand script" />
                        <FormControlLabel value="append" control={<Radio />} label="Voeg toe aan bestaand script" />
                    </RadioGroup>
                </FormControl>
            )}

            {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage}
                </Alert>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button 
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={loading || taskStatus === 'Task in progress'}
                    startIcon={loading || taskStatus === 'Task in progress' ? null : <CreateIcon />}
                    sx={{
                        backgroundColor: loading || taskStatus === 'Task in progress' ? 'grey.500' : 'primary.main',
                        color: 'white',
                        '&:disabled': {
                            backgroundColor: 'grey.500',
                            color: 'white',
                        },
                        padding: '12px 24px',
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        minWidth: '200px',
                        maxWidth: '400px',
                    }}
                >
                    {loading || taskStatus === 'Task in progress' ? (
                        <>
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    animation: `${pulse} 1s infinite ease-in-out`,
                                    mr: 1,
                                }}
                            />
                            <Typography variant="button">
                                {taskStatus === 'Task in progress' ? 'Script genereren...' : 'Taak wordt verwerkt...'}
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="button">
                            {hasGeneratedScript ? 'Genereer extra fragmenten' : 'Maak er een script van'}
                        </Typography>
                    )}
                </Button>
            </Box>
        </Box>
    );
});

export default ChatForm;