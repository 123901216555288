import React, { createContext, useState, useContext, useMemo } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme, createCustomTheme } from './theme';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [mode, setMode] = useState('light');
  const [selectedFormat, setSelectedFormat] = useState('');

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const setThemeByFormat = (formatId) => {
    setSelectedFormat(formatId);
  };

  const theme = useMemo(() => {
    if (selectedFormat) {
      return createCustomTheme(mode, selectedFormat);
    }
    return mode === 'light' ? lightTheme : darkTheme;
  }, [mode, selectedFormat]);

  const value = {
    mode,
    toggleTheme,
    setThemeByFormat,
    selectedFormat,
  };

  return (
    <ThemeContext.Provider value={value}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};