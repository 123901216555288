// src/hooks/useVoices.js
import { useState, useEffect } from 'react';
import axios from 'axios'; 
import config from '../config';

const useVoices = () => {
    const [voices, setVoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [audioElement, setAudioElement] = useState(null);

    useEffect(() => {
        const fetchVoices = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get_voices_with_label/type/${config.SITE_NAME}`);
                console.log("Response data:", response.data);
                setVoices(response.data);
            } catch (error) {
                console.error('Error fetching voices:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVoices();
    }, []);

    const playVoiceSample = async (voiceId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/play-voice-sample/${voiceId}`, {
                responseType: 'blob'
            });
            const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            setAudioElement(audio);
            audio.play();
        } catch (error) {
            console.error('Error playing voice sample:', error);
        }
    };

    const stopVoiceSample = async () => {
        if (audioElement) {
            audioElement.pause();
            audioElement.currentTime = 0;
            setAudioElement(null);
        }
    };

    return { voices, loading, playVoiceSample, stopVoiceSample };
};

export default useVoices;