import React from 'react';
import { Box, Container, Typography, Link, useTheme, Grid, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        mt: 4,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#121212', // Always dark
        color: theme.palette.common.white, // Always white text
        borderTop: `1px solid ${theme.palette.divider}`,
        opacity: 1,
        transform: 'translateY(0)',
        transition: 'opacity 0.5s, transform 0.5s',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="inherit" gutterBottom>
              Stemmy
            </Typography>
            <Typography variant="body2" color="inherit">
              Ontwerp audio-ervaringen met AI
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="inherit" gutterBottom>
              Contact
            </Typography>
            
            <Typography variant="body2" color="inherit">
              Email: twetering@gmail.com
            </Typography>
            
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="inherit" gutterBottom>
              Volgen
            </Typography>
            <Box>
              <IconButton 
                color="inherit" 
                aria-label="LinkedIn"
                component="a"
                href="https://www.linkedin.com/in/twetering/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="inherit" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://stemmy.nl">
              Stemmy.nl
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;