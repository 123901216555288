import { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Verwijder ongebruikte import
// import config from '../config';

const useGenerateMultipleVoices = () => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [taskId, setTaskId] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);

    const generateVoices = async (textVoicePairs, audioSettings, imageUrl = null, videoEnabled = false) => {
        setLoading(true);
        setError(null);
        setAudioUrl(null); // Reset the audioUrl
        setVideoUrl(null); // Reset the videoUrl
        setTaskStatus('Task in progress'); // Set the task status to 'Task in progress'

        const idempotencyKey = uuidv4(); // Generate a unique idempotency key for each request

        const showformat = audioSettings.format || '';
        const introPath = audioSettings.intro ? `${showformat}/intros/${audioSettings.intro}` : '';
        const outroPath = audioSettings.outro ? `${showformat}/outros/${audioSettings.outro}` : '';
        const bgAudioPath = audioSettings.bgaudio ? `${showformat}/bgaudio/${audioSettings.bgaudio}` : '';

        const requestUrl = `${process.env.REACT_APP_API_BASE_URL}/generate-multiple-voices`;
        const requestBody = { 
            textVoicePairs, 
            intro: introPath, 
            outro: outroPath, 
            bgaudio: bgAudioPath, 
            showformat,
            image_url: imageUrl,
            video_enabled: videoEnabled
        };
        const requestHeaders = { 
            'Content-Type': 'application/json',
            'Idempotency-Key': idempotencyKey // Include the idempotency key in the request header
        };

        console.log('Request URL:', requestUrl);
        console.log('Request Body:', requestBody);
        console.log('Request Headers:', requestHeaders);

        try {
            const response = await axios.post(requestUrl, requestBody, { headers: requestHeaders });
            setTaskId(response.data.task_id);
        } catch (e) {
            setError(e.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let intervalId;
    
        const checkTaskStatus = async () => {
            if (taskId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/task-status/${taskId}`);
                    const data = response.data;
    
                    if (data.status === 'Task completed') {
                        clearInterval(intervalId);
                        if (data.result && data.result.status === 'Error') {
                            setError(data.result.message || 'Er is een fout opgetreden bij het verwerken van de taak');
                            setLoading(false);
                        } else {
                            setAudioUrl(data.result.audio_file);
                            setVideoUrl(data.result.video_file);
                            setLoading(false);
                        }
                        setTaskId(null);
                    } else {
                        setTaskStatus(data.status);
                    }
                } catch (error) {
                    clearInterval(intervalId);
                    setError('Fout bij het ophalen van de taakstatus');
                    setLoading(false);
                    setTaskId(null);
                }
            }
        };
    
        if (taskId) {
            intervalId = setInterval(checkTaskStatus, 5000); // Check elke 5 seconden
        }
    
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [taskId]);

    return { audioUrl, videoUrl, generateVoices, loading, error, taskStatus };
};

export default useGenerateMultipleVoices;