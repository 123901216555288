// src/hooks/useAudioFiles.js
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios'; // importeer axios

const useAudioFiles = (folder) => {
    const [audioFiles, setAudioFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastFetchTime, setLastFetchTime] = useState(null);

    const fetchAudioFiles = useCallback(async (force = false) => {
        if (folder) {
            try {
                setLoading(true);
                const now = new Date().getTime();
                const cacheExpiration = 5 * 60 * 1000; // 5 minutes

                // Check if we need to fetch new data
                if (force || !lastFetchTime || (now - lastFetchTime > cacheExpiration)) {
                    console.log(`Fetching audio files for folder: ${folder}`);
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/media?folder=${folder}`);
                    console.log('Received audio files:', response.data.files);
                    const filesWithShortNames = response.data.files.map(file => ({
                        ...file,
                        filename: file.filename.split('/').pop()
                    }));
                    setAudioFiles(filesWithShortNames);
                    setLastFetchTime(now);
                    
                    // Store in localStorage
                    localStorage.setItem(`audioFiles_${folder}`, JSON.stringify(filesWithShortNames));
                    localStorage.setItem(`lastFetchTime_${folder}`, now.toString());
                } else {
                    console.log('Using cached audio files');
                    // Retrieve cached files if we're not fetching new ones
                    const cachedFiles = localStorage.getItem(`audioFiles_${folder}`);
                    if (cachedFiles) {
                        setAudioFiles(JSON.parse(cachedFiles));
                    }
                }
            } catch (error) {
                console.error('Error fetching audio files:', error);
                setAudioFiles([]);
            } finally {
                setLoading(false);
            }
        } else {
            console.log('No folder specified, setting audio files to empty array');
            setAudioFiles([]);
            setLoading(false);
        }
    }, [folder]);

    useEffect(() => {
        const cachedFiles = localStorage.getItem(`audioFiles_${folder}`);
        const cachedLastFetchTime = localStorage.getItem(`lastFetchTime_${folder}`);

        if (cachedFiles && cachedLastFetchTime) {
            setAudioFiles(JSON.parse(cachedFiles));
            setLastFetchTime(parseInt(cachedLastFetchTime));
            setLoading(false);
        } else {
            fetchAudioFiles(true);  // Force fetch if no cached data
        }
    }, [fetchAudioFiles, folder]);

    const getAudioUrl = async (filename) => {
        try {
            console.log(`Fetching audio URL for file: ${filename}`);
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/audio-url?filename=${filename}`);
            console.log('Received audio URL:', response.data.url);
            return response.data.url;
        } catch (error) {
            console.error('Error fetching audio URL:', error);
            throw error;
        }
    };

    const generateSoundEffect = async (prompt, duration, audioType, format) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/generate-sound-effect`, {
                prompt,
                duration: duration === null ? null : parseFloat(duration),
                audioType,
                format
            });
            return response.data.task_id;
        } catch (error) {
            console.error('Error starting sound effect generation:', error);
            throw error;
        }
    };

    const checkSoundEffectStatus = async (taskId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/sound-effect-status/${taskId}`);
            return response.data;
        } catch (error) {
            console.error('Error checking sound effect status:', error);
            throw error;
        }
    };

    const saveSoundEffect = async (tempS3Key, audioType, format, prompt, duration) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/save-sound-effect`, {
                tempS3Key,
                audioType,
                format,
                prompt,
                duration
            });
            await fetchAudioFiles(true); // Force refresh the list of audio files
            return response.data.filePath;
        } catch (error) {
            console.error('Error saving sound effect:', error);
            throw error;
        }
    };

    const refreshAudioFiles = () => {
        return fetchAudioFiles(true);
    };

    return { audioFiles, loading, getAudioUrl, generateSoundEffect, saveSoundEffect, refreshAudioFiles, checkSoundEffectStatus };
};

export default useAudioFiles;

// const response = await axios.get(`https://surrounded-3fe36f6a1847.herokuapp.com/api/media?folder=${folder}`);