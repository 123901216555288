import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Box, IconButton, Grid, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import GetAppIcon from '@mui/icons-material/GetApp';
import ReplayIcon from '@mui/icons-material/Replay';

const InstructionStep = ({ icon, text }) => (
  <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center', height: '100%' }}>
    <Box sx={{ mr: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40, height: 40, borderRadius: '50%', backgroundColor: 'primary.main', color: 'white' }}>
      {icon}
    </Box>
    <Typography variant="body1">{text}</Typography>
  </Paper>
);

const InstructionsModal = ({ open, onClose }) => {
  const steps = [
    { icon: <FormatListNumberedIcon />, text: "Kies een format (bijv. Tel Sell)." },
    { icon: <ChatIcon />, text: "Typ of kopieer/plak de inhoud die je in het script wilt zien." },
    { icon: <SettingsIcon />, text: "Klik op 'Maak er een script van'." },
    { icon: <MusicNoteIcon />, text: "Optioneel: pas de intro/outro en/of achtergrondmuziek aan." },
    { icon: <RecordVoiceOverIcon />, text: "Optioneel: wijzig de stem per fragment." },
    { icon: <MusicNoteIcon />, text: "Optioneel: pas het achtergrondgeluid per fragment aan." },
    { icon: <EditIcon />, text: "Optioneel: wijzig de tekst of voeg extra fragmenten toe." },
    { icon: <PlayArrowIcon />, text: "Genereer je Stemmy." },
    { icon: <GetAppIcon />, text: "Beluister en/of download je Stemmy." },
    { icon: <ReplayIcon />, text: "Maak nog een Stemmy!" },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 0 }} className="typographyHeader">
          <Typography variant="h5" sx={{ mb: 0 }}>Hoe maak je een Stemmy?</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {steps.map((step, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <InstructionStep icon={step.icon} text={`${index + 1}. ${step.text}`} />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InstructionsModal;