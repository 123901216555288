import { createTheme } from '@mui/material/styles';
import backgroundPattern from '../assets/background-pattern.png';
import formats from '../formats.json';

const createCustomTheme = (mode, formatId) => {
  let themeOptions = {
    palette: {
      mode,
      primary: {
        main: mode === 'light' ? '#1976d2' : '#90caf9',
      },
      secondary: {
        main: mode === 'light' ? '#dc004e' : '#f48fb1',
      },
      background: {
        default: mode === 'light' ? '#f5f5f5' : '#121212',
        paper: mode === 'light' ? '#ffffff' : '#1e1e1e',
      },
      text: {
        primary: mode === 'light' ? '#000000' : '#ffffff',
        secondary: mode === 'light' ? '#757575' : '#a0a0a0',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: mode === 'light' ? '#f5f5f5' : '#121212',
            color: mode === 'light' ? '#000000' : '#ffffff',
            backgroundImage: `url(${backgroundPattern})`,
            backgroundRepeat: 'repeat',
            backgroundSize: '100px 100px',
          },
        },
      },
    },
  };

  if (formatId) {
    const format = formats.formats.find(f => f.id === formatId);
    if (format && format.theme) {
      themeOptions.palette = {
        ...themeOptions.palette,
        mode: 'light',
        primary: { main: format.theme.primary },
        secondary: { main: format.theme.secondary },
        background: { default: format.theme.background, paper: format.theme.paper },
        text: { primary: format.theme.text, secondary: format.theme.text },
        icon: { main: format.iconColor },
      };

      // Voeg componentstijlen toe als ze bestaan
      if (format.theme.components) {
        themeOptions.components = {
          ...themeOptions.components,
          ...format.theme.components,
        };
      }
    }
  }

  themeOptions.components.MuiCssBaseline.styleOverrides['.draggableContainer'] = {
    backgroundColor: themeOptions.palette.background.paper,
    color: themeOptions.palette.text.primary,
  };

  return createTheme(themeOptions);
};

const lightTheme = createCustomTheme('light');
const darkTheme = createCustomTheme('dark');

export { lightTheme, darkTheme, createCustomTheme };